var styles =[
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "gamma": "1.82"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "gamma": "1.96"
            },
            {
                "lightness": "-9"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": "25"
            },
            {
                "gamma": "1.00"
            },
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f9f8f6"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#f3e9e4"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "lightness": "-2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "lightness": "59"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "saturation": "-43"
            },
            {
                "color": "#eceae5"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ffaa00"
            },
            {
                "saturation": "-70"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "-100"
            },
            {
                "lightness": "30"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-100"
            },
            {
                "lightness": "33"
            },
            {
                "visibility": "on"
            },
            {
                "gamma": "1"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "gamma": "0.80"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#cadee6"
            },
            {
                "lightness": "-18"
            },
            {
                "gamma": "1"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#cadee6"
            }
        ]
    }
];

var isNotEmptyString = function(str) {
	if (!str) {
		return str.trim().length;
	}
	return 0;
};

var init = function($mapWrapper){
    var maxZoom    = 17,
        $mapCanvas   = $mapWrapper.find('.map-canvas'),
        mapCanvasOY  = isNaN(parseInt($mapWrapper.data('map-height'))) ? parseInt($mapCanvas.width() * 0.66) : parseInt($mapWrapper.data('map-height')),
        locations    = $mapWrapper.data('locations'),
        mapType      = $mapWrapper.data('map-type'),
        iconUrl = $mapWrapper.data('icon'),
        iconX = $mapWrapper.data('icon-x'),
        iconY = $mapWrapper.data('icon-y'),
        isDraggable = Modernizr.touch ? false : true;
        markerTarget = $mapWrapper.data('marker-target'),
        disableScroll = ($mapWrapper.data('disable-scrolling') ? true : false),
        mapOptions   = {
            center: ( 'undefined' !== locations && locations.length) ? calculateCenter(locations) :  'new google.maps.LatLng(-34, 150)',
            mapTypeId: google.maps.MapTypeId[mapType],
            scrollwheel: disableScroll,
            styles: styles,
            panControl:false,
            mapTypeControl: false,
            streetViewControl: false,
            draggable: isDraggable,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
        },
        markerBounds = new google.maps.LatLngBounds(),
        map          = new google.maps.Map($mapCanvas.get(0), mapOptions);
        

        if ('undefined' !== locations && locations.length) {
            locations.forEach(function(location){
                gMapsCoords = new google.maps.LatLng(location.coordinates.lat, location.coordinates.lng);
                
                var icon = {
                    url: iconUrl,
					size: new google.maps.Size(50, 64),
                    scaledSize: new google.maps.Size(50,64),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(25, 64)
                };

                var marker = new google.maps.Marker({
                    position: gMapsCoords,
                    map: map,
                    icon: icon,
                    optimized: false,
                    zIndex: 1,
                    url: markerTarget
                });
                markerBounds.extend(gMapsCoords);

                //set content InfoWindow template
                if ( isNotEmptyString(location.description) || isNotEmptyString(location.title) || isNotEmptyString(location.url) || isNotEmptyString(location.thumb) ) {

                    var template = _.template(
                        "<% function isNotEmptyString(str) { if (_.isString(str)) {	return str.trim().length;} return 0; }  %>" +

                            "<div class='infowindow'>" +

                                "<% if (isNotEmptyString(location.thumb)) { %>" +
                                    "<div class='infowindow-thump'>" +
                                        "<img src='<%= location.thumb %>' >" +
                                    "</div> " +
                                "<% } %>" +

                                "<div class='infowindow-content'>" +
                                    "<% if ( isNotEmptyString(location.url) || isNotEmptyString(location.title) ) { %>" +
                                        "<div class='infowindow-title'>" +
                                            "<a href='<%- location.url %>'><%- isNotEmptyString(location.title) ?  location.title : location.url  %></a>" +
                                        "</div>" +
                                    "<% } %>" +
                                    "<% if ( isNotEmptyString(location.description) ) { %>"+
                                        "<div class='infowindow-description'>" +
                                            "<%= location.description %>" +
                                        "</div>" +
                                    "<% } %>" +
                                "</div>" +

                            "</div>");

                    var infowindow = new google.maps.InfoWindow({
                        content: template({location: location})
                    });
                    
                    infowindow.open(map,marker);

                    // google.maps.event.addListener(marker, 'click', function() {
                    //     infowindow.open(map,marker);
                    // });
                    
                }
                
                google.maps.event.addListener(marker, 'click', function() {
                    window.open(this.url, '_blank');
                });
            });
        }

        //change "zoom"
        map.fitBounds(markerBounds);
        
        //center after resize
        google.maps.event.addDomListener(window, 'resize', function() {
          map.setCenter(calculateCenter(locations));
        });

        //change zoom to max zoom
        var listener = google.maps.event.addListenerOnce(map, 'zoom_changed', function() {
            if (map.getZoom() > maxZoom) map.setZoom(maxZoom);
            google.maps.event.removeListener(listener);
        });

        $mapCanvas.height(mapCanvasOY);
        $mapCanvas.data('map', map);
};

var calculateCenter = function(locations) {
    var Lng,Hyp,Lat,
        total = locations.length,
        X = 0,
        Y = 0,
        Z = 0,
		offset = 0;
		
	// if ( window.innerWidth > 1400 ) offset = 0.0003;

    locations.forEach(function(location){
        var lat = location.coordinates.lat * Math.PI / 180,
            lng = location.coordinates.lng * Math.PI / 180,
            x = Math.cos(lat) * Math.cos(lng),
            y = Math.cos(lat) * Math.sin(lng),
            z = Math.sin(lat);

        X += x;
        Y += y;
        Z += z;
    });

    X /= total;
    Y /= total;
    Z /= total;

    Lng = Math.atan2(Y, X);
    Hyp = Math.sqrt(X * X + Y * Y);
    Lat = Math.atan2(Z, Hyp);
    
    return { lng: (Lng * 180 / Math.PI), lat: ((Lat * 180 / Math.PI) + offset) };
};

$(window).load(function(){
    init($('#gmap'));
});
