(function($){
    function createCookie(name,value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name,"",-1);
    }

    jQuery(document).ready(function() {
        var $popup = $('[data-popup-banner]');

        if ($popup.length === 0) {
            return;
        }

        var $popupToggle = $('[data-popup-banner] [data-popup-banner-toggle]');
        var cookie = readCookie('popupBannerHidden');

        if (cookie !== null) {
            $popup.addClass('is-closed');
            createCookie('popupBannerHidden', true, 30);
        } else {
            $popup.removeClass('is-closed');
            eraseCookie('popupBannerHidden');
        }

        $popupToggle.on('click', function() {
            cookie = readCookie('popupBannerHidden');

            if (cookie === null) {
                $popup.addClass('is-closed');
                createCookie('popupBannerHidden', true, 30);
            } else {
                $popup.removeClass('is-closed');
                eraseCookie('popupBannerHidden');
            }
        });
    });
})(jQuery);
