/**
 * Create an instance of this class to track click or selection (mousedown anywhere followed by mouseover on this element)
 * of given element and send it as event directly to Google Analytics or to dataLayer for GTM.
 * Usage:
 *	var phone = document.getElementsByClassName('icon-phone')[0];
 *	new NMInteractionTracker(phone, 'phone');
 *	new NMInteractionTracker(phone, 'phone', 'gtm', 100, {dimension1: 'logged user', dimension2: NMGetTimestampISO()}, true); // debugging
 * @param element       Element to track.
 * @param elementName   Description of event action that should be sent to GA.
 * @param trackerType   Use 'ga' for Universal Analytics or 'gtm' for Google Tag Manager (push event to dataLayer). Defaults to 'ga'.
 * @param eventValue    Numeric value of that event. Defaults to 0.
 * @param fieldsObject  Optional fields for GA with dimensions, nonInteraction etc.
 * @param debugging     Debugging output to console. Defaults to false.
 */
function NMInteractionTracker(element, elementName, trackerType, eventValue, fieldsObject, debug) {
	var value;
	var tracker;
	var eventSent = [];
	var eventCategory = 'NMInteractionTracker';
    var eventAction;
	var eventLabel;

	var sendGTMEvent = function(event) {
		dataLayer.push({
			'event': 'NMInteractionTracker',
			'eventCategory': eventCategory,
			'eventAction': eventAction,
			'eventLabel': eventLabel,
			'eventValue': eventValue,
			'customFields': fieldsObject
		});
	};

	var sendGAEvent = function(event) {
		ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue, fieldsObject);
	};

	var sendEventOnce = function(event) {
		if (!eventSent[event.type]) {
			eventSent[event.type] = true;
			setGAEventVars(event);
			debug ? console.log('firing event: ' + [eventAction, eventLabel, eventValue].join(', ')) : null;
			tracker(event);
		}
	};

	var setGAEventVars = function(jsEvent) {
		debug ? console.log(jsEvent) : null;
        eventAction = jsEvent.type == 'mouseover' ? 'mouseover-select' : jsEvent.type;
		eventLabel = elementName + '|' + jsEvent.target.innerText + ' in tag ' + jsEvent.target.localName + '.' + jsEvent.target.className;
		eventValue = parseInt(value);
	};

	var addEventListeners = function() {
		element.addEventListener('click', sendEventOnce);

        // add mouseover listener for start of selection
        document.addEventListener('mousedown', function() {
            element.addEventListener('mouseover', sendEventOnce);
            debug ? console.log('registered mouseover to ' + element) : null;
        });

        // remove after mouse is released
        document.addEventListener('mouseup', function() {
            element.removeEventListener('mouseover', sendEventOnce);
            debug ? console.log('unregistered mouseover from ' + element) : null;
        });

        // remove after mouse is released - drag fires mousedown, but doesn't fire mouseup
        document.addEventListener('dragstart', function() {
            element.removeEventListener('mouseover', sendEventOnce);
            debug ? console.log('unregistered mouseover from ' + element) : null;
        });
	};

	/**
	 *	Constructor
	 */
	{
		if (typeof element === 'undefined') {
			return;
		}
		tracker = (trackerType == 'gtm' ? sendGTMEvent : sendGAEvent);
		value = (typeof eventValue !== 'undefined' ? eventValue : 0);
		addEventListeners();
	}
}


/**
 * Gets ISO timestamp useful for filtering spam visits
 */
function NMGetTimestampISO() {
    var now = new Date();
    var tzo = -now.getTimezoneOffset();
    var dif = tzo >= 0 ? '+' : '-';
    var pad = function(num) {
        var norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return now.getFullYear()
        + '-' + pad(now.getMonth()+1)
        + '-' + pad(now.getDate())
        + 'T' + pad(now.getHours())
        + ':' + pad(now.getMinutes())
        + ':' + pad(now.getSeconds())
        + '.' + pad(now.getMilliseconds())
        + dif + pad(tzo / 60)
        + ':' + pad(tzo % 60);
}
