(function($){
    $(document).foundation();

    svg4everybody({
        // nosvg: true, // shiv <svg> and <use> elements and use image fallbacks 
        polyfill: true // polyfill <use> elements for External Content 
    });

    // $(document).ready(function() {
    //     $("#js-main-nav-links")
    //         .on("on.zf.toggler", function(e) {
    //             $("#js-toggle").addClass('active');
    //         })
    //         .on("off.zf.toggler", function(e) {
    //             $("#js-toggle").removeClass('active');
    //         });
    // });
})(jQuery);
